import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a className="sel" href="/upravte_liek/">
                    Test
                  </a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/upravte_liek/" className="sel">
              {" "}
              &gt; Test
            </a>
          </div>
          <div id="page_content" className="rel">
            <div id="quiz">
              <div className="window">
                <div className="quiz_start active">
                  <div className="content">
                    <p>
                      ODPOVEDZTE NA NIEKOĽKO OTÁZOK A NÁJDITE NAJVHODNEJŠÍ LIEK
                    </p>
                  </div>
                  <span className="btn_start">Začať test </span>
                </div>
                <div
                  className="question q_2 first"
                  data-prev=""
                  data-products=".product_2,.product_3,.product_4,.product_5,.product_6"
                >
                  <div className="content">
                    <p>Pre koho má byť liek určený?</p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="4"
                      data-qid="2"
                      data-next="3"
                    >
                      Deti 2-6 rokov
                    </span>
                    <span
                      className="answer "
                      data-aid="17"
                      data-qid="2"
                      data-next="6"
                    >
                      <p>Deti od 6 rokov a dospelí</p>
                    </span>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px"
                    }}
                  ></div>
                  <span className="back answerback">SPÄŤ</span>
                </div>
                <div
                  className="question q_3"
                  data-prev=""
                  data-products=".product_2,.product_3,.product_4,.product_5,.product_6"
                >
                  <div className="content">
                    <p>Myslíte si, že je nosová sliznica vášho dieťaťa vysušená?</p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="5"
                      data-qid="3"
                      data-next="4"
                    >
                      Áno
                    </span>
                    <span
                      className="answer "
                      data-aid="15"
                      data-qid="2"
                      data-next=""
                    >
                      <p>Nie</p>
                    </span>
                    <div className="resume resume_15">
                      <p className="name">VÝSLEDOK</p>
                      <div className="wysiwyg">
                        <p>
                        Liek vo forme nosového roztokového spreja 
                        na liečbu príznakov nádchy. Zmierňuje prekrvenie a opuch nosovej sliznice 
                        a urýchľuje uvoľnenie sekrécie.
                        </p>
                        <p>
                          <img
                            alt
                            src="/web/kcfinder/uploads/images/sk/Olynth_Pack-Sprej_SK_Olynth_0.05_2023_Left_NEW.png"
                            style={{
                              
                              height: "300px"
                            }}
                          />
                        </p>
                        <p>
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900"
                            }}
                          >
                            OLYNTH
                          </span>
                          <sup>
                            <strong>®</strong>
                          </sup>
                          &nbsp;
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900"
                            }}
                          >
                            0,05%
                          </span>
                          <br />
                          <br />
                          <a className="more" href="/lieky/olynth_05/">
                            VIAC
                          </a>
                        </p>
                      </div>
                      <span className="back resumeback">SPÄŤ</span>
                    </div>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px"
                    }}
                  ></div>
                  <span className="back answerback">SPÄŤ</span>
                </div>
                <div
                  className="question q_4 "
                  data-prev=""
                  data-products=".product_3,.product_5"
                >
                  <div className="content">
                    <p>
                      Myslíte si, že je nosová sliznica Vášho dieťaťa podráždená?
                    </p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="6"
                      data-qid="4"
                      data-next=""
                    >
                      <p>Nie</p>
                    </span>
                     <div className="resume resume_6">
                      <p className="name">VÝSLEDOK</p>
                      <div className="wysiwyg">
                        <p>
                        Liek vo forme nosového roztokového spreja.
                        Zmierňuje prekrvenie a opuch nosovej sliznice a urýchľuje uvoľnenie sekrécie.
                        Zvlhčuje nosovú sliznicu vďaka trom zvlhčujúcim látkam.
                        </p>
                        <p>
                          <img
                            alt
                            src="/web/kcfinder/uploads/images/sk/Olynth_Pack-Sprej_SK_2023_HA_0.05 perc_2023_left.png"
                            style={{
                              
                              height: "300px"
                            }}
                          />
                        </p>
                        <p>
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900"
                            }}
                          >
                            OLYNTH
                          </span>
                          <sup>
                            <strong>®</strong>
                          </sup>
                          &nbsp;
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900"
                            }}
                          >
                            HA 0,05%
                          </span>
                          <br />
                          <br />
                          <a className="more" href="/lieky/olynth_ha_05/">
                            VIAC
                          </a>
                        </p>
                      </div>
                      <span className="back resumeback">SPÄŤ</span>
                    </div> 
                    <span
                      className="answer "
                      data-aid="16"
                      data-qid="4"
                      data-next=""
                    >
                      <p>Áno</p>
                    </span>
                    <div className="resume resume_16">
                      <p className="name">VÝSLEDOK</p>
                      <div className="wysiwyg">
                        <p> Liek vo forme nosového roztokového spreja. Prináša 4 benefity v 1 produkte:
                          <ul>
                            <li>Uvoľňuje upchatý nos</li>
                            <li>Urýchľuje hojenie poranenej nosovej sliznice</li>
                            <li>Chráni nosovú sliznicu</li>
                            <li>Bez obsahu konzervačných látok</li>
                          </ul>
                        </p>
                        <p>
                          <img
                            alt
                            src="/web/kcfinder/uploads/images/sk/Olynth_Pack-Sprej_SK_2023_PLUS_0.5mg_left.png"
                            style={{
                              
                              height: "211px"
                            }}
                          />
                        </p>
                        <p>
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900"
                            }}
                          >
                            OLYNTH<sup>®</sup> PLUS 0,5 mg/50 mg/ml
                          </span>
                          <br />
                          <br />
                          <a className="more" href="/lieky/olynth_plus_05/">
                            VIAC
                          </a>
                        </p>
                      </div>
                      <span className="back resumeback">SPÄŤ</span>
                    </div>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px"
                    }}
                  ></div>
                  <span className="back answerback">SPÄŤ</span>
                </div>
                <div
                  className="question q_5"
                  data-prev=""
                  data-products=".product_2,.product_3,.product_4,.product_5,.product_6"
                >
                  <div className="content">
                    <p>Myslíte si, že je nosová sliznica Vášho dieťaťa vysušená?</p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="18"
                      data-qid="2"
                      data-next="3"
                    >
                      Nie
                    </span>
                    <div className="resume resume_18">
                      <p className="name">VÝSLEDOK</p>
                      <div className="wysiwyg">
                        <p>
                        Liek vo forme nosovej roztokovej aerodisperzie (nosového spreja).
                        Zmierňuje prekrvenie a opuch nosovej sliznice a urýchľuje uvoľnenie sekrécie.
                        Zvlhčuje nosovú sliznicu vďaka trom zvlhčujúcim látkam.
                        </p>
                        <p>
                          <img
                            alt
                            src="/web/kcfinder/uploads/images/sk/Olynth_005.png"
                            style={{
                              
                              height: "211px"
                            }}
                          />
                        </p>
                        <p>
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900"
                            }}
                          >
                            OLYNTH<sup>®</sup> HA 0,05%

                          </span>
                          <br />
                          <br />
                          <a className="more" href="/lieky/olynth_ha_05/">
                            VIAC
                          </a>
                        </p>
                      </div>
                      <span className="back resumeback">SPÄŤ</span>
                    </div>
                    <span
                      className="answer "
                      data-aid="19"
                      data-qid="2"
                      data-next="4"
                    >
                      <p>Áno</p>
                    </span>
                    <div className="resume resume_18">
                      <p className="name">VÝSLEDOK</p>
                      <div className="wysiwyg">
                        <p>
                          Liek vo forme nosovej roztokovej aerodisperzie
                          (nosového spreja) na liečbu príznakov nádchy.
                          Zmierňuje prekrvenie a opuch nosovej sliznice a
                          urýchľuje uvoľnenie sekrécie.
                        </p>
                        <p>
                          <img
                            alt
                            src="/web/kcfinder/uploads/images/sk/Olynth_005.png"
                            style={{
                              
                              height: "211px"
                            }}
                          />
                        </p>
                        <p>
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900"
                            }}
                          >
                            OLYNTH<sup>®</sup> PLUS 0,5 mg/50 mg/ml nosová roztoková aerodisperzia
                          </span>
                          <br />
                          <br />
                          <a className="more" href="/lieky/olynth_plus_05/">
                            VIAC
                          </a>
                        </p>
                      </div>
                      <span className="back resumeback">SPÄŤ</span>
                    </div>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px"
                    }}
                  ></div>
                  <span className="back answerback">SPÄŤ</span>
                </div>
                <div
                  className="question q_6 "
                  data-prev=""
                  data-products=".product_4,.product_6"
                >
                  <div className="content">
                    <p>
                      Myslíte si, že je vaša nosová sliznica vysušená a potrebujete
                      ju zvlhčiť?
                    </p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="7"
                      data-qid="6"
                      data-next="7"
                    >
                      <p>Áno</p>
                    </span>
                    <span
                      className="answer "
                      data-aid="17"
                      data-qid="4"
                      data-next=""
                    >
                      <p>Nie</p>
                    </span>
                    <div className="resume resume_17">
                      <p className="name">VÝSLEDOK</p>
                      <div className="wysiwyg">
                        <p className="p1">
                        Liek vo forme nosového roztokového spreja.
                        Zmierňuje prekrvenie a opuch nosovej sliznice a urýchľuje uvoľnenie sekrécie.
                        </p>
                        <p>
                          <img
                            src="/web/kcfinder/uploads/images/sk/Olynth_Pack-Sprej_SK_2023_0.1_2023_Left_NEW.png"
                            style={{
                              
                              height: "300px"
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900"
                            }}
                          >
                            OLYNTH<sup>®</sup> 0,1%
                          </span>
                          <br />
                          <br />
                          <a className="more" href="/lieky/olynth_01/">
                            VIAC
                          </a>
                        </p>
                      </div>
                      <span className="back resumeback">SPÄŤ</span>
                    </div>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px"
                    }}
                  ></div>
                  <span className="back answerback">SPÄŤ</span>
                </div>
                <div
                  className="question q_7 "
                  data-prev=""
                  data-products=".product_4,.product_6"
                >
                  <div className="content">
                    <p>
                    Máte pocit, že je vaša nosová sliznica podráždená?
                    </p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="19"
                      data-qid="6"
                      data-next=""
                    >
                      <p>Nie</p>
                    </span>
                    <div className="resume resume_19">
                      <p className="name">VÝSLEDOK</p>
                      <div className="wysiwyg">
                        <p className="p1">
                        Liek vo forme nosového roztokového spreja.
                        Zmierňuje prekrvenie a opuch nosovej sliznice a urýchľuje uvoľnenie sekrécie.
                        Zvlhčuje nosovú sliznicu vďaka trom zvlhčujúcim látkam.
                        </p>
                        <p>
                          <img
                            src="/web/kcfinder/uploads/images/sk/Olynth_Pack-Sprej_SK_2023_HA_0.1 perc_2023_left.png"
                            style={{
                              
                              height: "300px"
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900"
                            }}
                          >
                            OLYNTH<sup>®</sup> HA 0,1%
                          </span>
                          <br />
                          <br />
                          <a className="more" href="/lieky/olynth_ha_01/">
                            VIAC
                          </a>
                        </p>
                      </div>
                      <span className="back resumeback">SPÄŤ</span>
                    </div>
                    <span
                      className="answer "
                      data-aid="20"
                      data-qid="4"
                      data-next=""
                    >
                      <p>Áno</p>
                    </span>
                    <div className="resume resume_20">
                      <p className="name">VÝSLEDOK</p>
                      <div className="wysiwyg">
                        <p className="p1">
                        Liek vo forme nosového roztokového spreja. Prináša 4 benefity v 1 produkte:
                        <ul>
                          <li>Uvoľňuje upchatý nos</li>
                          <li>Urýchľuje hojenie poranenej nosovej sliznice</li>
                          <li>Chráni nosovú sliznicu</li>
                          <li>Bez obsahu konzervačných látok</li>
                        </ul>
                        </p>
                        <p>
                          <img
                            src="/web/kcfinder/uploads/images/sk/Olynth_Pack-Sprej_SK_2023_PLUS_1mg_left.png"
                            style={{
                              
                              height: "300px"
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900"
                            }}
                          >
                            OLYNTH<sup>®</sup> PLUS 1 mg/50 mg/ml
                          </span>
                          <br />
                          <br />
                          <a className="more" href="/lieky/olynth_plus_01/">
                            VIAC
                          </a>
                        </p>
                      </div>
                      <span className="back resumeback">SPÄŤ</span>
                    </div>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px"
                    }}
                  ></div>
                  <span className="back answerback">SPÄŤ</span>
                </div>
              </div>
            </div>
          </div>
          <div className="choice_product">
            <div className="col product_3">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_Olynth_0.05_2023_Left_NEW.png" />
                </div>
              </div>
              <p className="name">
                OLYNTH<sup>®</sup> 0,05%
              </p>
            </div>
            <div className="col product_5">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_HA_0.05 perc_2023_left.png" />
                </div>
              </div>
              <p className="name">
                OLYNTH<sup>®</sup> HA 0,05%{" "}
              </p>
            </div>
            <div className="col product_5">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_PLUS_0.5mg_left.png" />
                </div>
              </div>
              <p className="name">
              OLYNTH<sup>®</sup> PLUS <br />0,5 mg/50 mg/ml <br />{" "}
              </p>
            </div>
            <div className="col product_4">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_0.1_2023_Left_NEW.png" />
                </div>
              </div>
              <p className="name">
                OLYNTH<sup>®</sup> 0,1%
              </p>
            </div>
            <div className="col product_6">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_HA_0.1 perc_2023_left.png" />
                </div>
              </div>
              <p className="name">
                OLYNTH<sup>®</sup> HA 0,1%
              </p>
            </div>
            <div className="col product_5">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_PLUS_1mg_left.png" />
                </div>
              </div>
              <p className="name">
                OLYNTH<sup>®</sup> PLUS <br />1 mg/50 mg/ml <br />{" "}
              </p>
            </div>
          </div>
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
